<!--
 * @Description: 滚动列表
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-31 13:52:07
 * @LastEditors: hs
 * @LastEditTime: 2021-10-27 19:07:45
-->
<template>
  <div class="visula-scroll" :style="{ height: scrollHeight + 'px' }">
    <div class="visula-scroll-title">
      <div class="title-left">
        <template v-if="titleIcon !== ''">
          <slot name="left">
            <img :src="titleIcon" />
          </slot>
        </template>
        <span>{{ title }}</span>
      </div>
    </div>

    <div class="visula-scroll-list">
      <vue-seamless-scroll
        :data="scrollData"
        class="seamless-warp"
        :style="{ height: scrollHeight - 36 - 16 + 'px' }"
        :class-option="defaultOption"
      >
        <div v-if="!content">
          <div
            v-for="(item, index) in scrollData"
            :key="item.id"
            class="visula-scroll-item"
            :class="`is-level-${item.level}`"
          >
            <div class="visula-scroll-item-left">
              <img v-if="item.image" :src="item.image" alt />
              <img v-else :src="require(`@/assets/mock/alarm.png`)" />
              <!-- ${item.name} -->
            </div>

            <div class="visula-scroll-item-right">
              <p class="item-right-top">
                <span :title="item.title">{{ item.title }}</span>
                <span>
                  <img v-if="item.isHandling == 0" src="@/assets/images/home/redSquare.png" />
                  <span v-if="item.isHandling == 0" class="is-active-y">待处理</span>
                  <img v-if="item.isHandling == 1" src="@/assets/images/home/cryinSquare.png" />
                  <span v-if="item.isHandling == 1" class="is-active-v">处理中</span>
                  <img v-if="item.isHandling >= 2" src="@/assets/images/home/graySquare.png" />
                  <span v-if="item.isHandling >= 2" class="is-active-w">已处理</span>
                </span>
                <!-- <img src="@/assets/images/home/operation.png" v-if="showBottom"/> -->
              </p>

              <p v-if="item.time" class="item-right-time">{{ item.time }}</p>
              <p v-if="item.address" class="item-right-address">{{ item.address }}</p>
              <div v-if="showBottom" class="item-right-bottom">
                <div
                  v-if="item.type === '低压类型'"
                  class="item-right-bottom-left"
                  style="color: #17E6A1"
                >{{ item.type }}</div>
                <div
                  v-else-if="item.type === '中压类型'"
                  class="item-right-bottom-left"
                  style="color: #FFCC1D"
                >{{ item.type }}</div>
                <div
                  v-else-if="item.type === '微高压类型'"
                  class="item-right-bottom-left"
                  style="color: #FF9000"
                >{{ item.type }}</div>
                <div
                  v-else-if="item.type === '高压类型'"
                  class="item-right-bottom-left"
                  style="color: #FF3030"
                >{{ item.type }}</div>
                <div v-else></div>
                <div class="item-right-bottom-right">
                  <img
                    v-if="item.name === 'abnormal'"
                    src="@/assets/images/home/see.svg"
                    @click="fn_open__video"
                  />
                  <img
                    v-if="item.isDeleted == 0 && item.name != 'abnormal'"
                    src="@/assets/images/home/delete.svg"
                    @click="handleDelete(item)"
                  />
                  <img src="@/assets/images/home/view.svg" @click="fn_sure_point(index)" />
                  <!-- 工单  --- 暂时隐藏 -->
                  <!-- <img src="@/assets/images/home/detail.svg" /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <slot v-else name="content"></slot>
      </vue-seamless-scroll>
    </div>

    <visual-dialog :show.sync="isShowVideo">
      <div class="videoDetail">
        <video-panel
          key="active"
          class="video-panel-active"
          :style="{ zoom: 1 / zoom }"
          :height="25"
          :width="48.7"
          :o-web-control="'oWeb' + '_' + 'carDetailWrap'"
          :value="['bf1cf88cb1454de8aac41f25782a722d']"
          :options="{
            buttonIDs: '',
            showToolbar: 0,
            layout: '1x1'
          }"
          func-name="startPreview"
          @home-open-camera="fn_open__camera"
        />
      </div>
    </visual-dialog>
  </div>
</template>

<script>
import VisualDialog from '@/views/park/components/visual-dialog/index.vue'
import VideoPanel from '@/views/park/components/visual-video/index.vue'

import { mapGetters } from 'vuex'

import { devices } from '@/api/mock/json.js'
import { fn_api_get_park_alarm_remove } from '../../api/park/home'
export default {
  name: 'VisualScroll',
  components: {
    VisualDialog,
    VideoPanel
  },
  props: {
    scrollData: {
      type: Array,
      default: () => []
    },
    content: {
      type: Boolean,
      default: false // 右侧自定义数据
    },
    title: {
      type: String,
      default: '动态标题'
    },
    titleIcon: {
      type: [String, Boolean],
      default: ''
    },
    scrollHeight: {
      type: Number,
      default: 430
    },
    icon: {
      type: Boolean,
      default: false
    },
    showBottom: {
      type: Boolean,
      default: true
    },
    singleHeight: {
      type: Number,
      default: 100 // 8.16  ui第一版 单项高度
    },
    waitTime: {
      type: Number,
      default: 2000 //
    }
  },
  computed: {
    ...mapGetters(['zoom']),
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 5, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: this.singleHeight, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: this.waitTime // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  data() {
    return {
      colorType: '',
      isShowVideo: false
    }
  },
  created() {},
  methods: {
    fn_sure_point(index) {
      // const data = {
      //   name: "sos",
      //   sMark: "XMHJMF-M",
      //   bMark: "XMHJMF-2D",
      //   lMark: "XMHJMF-2D-19F",
      //   position: [-111.89, 83.375, 364.04],
      //   type: "SOS",
      //   productKey: "ddsff",
      //   deviceName: "effstsd"
      // };
      // let index = Math.floor(Math.random() * 21) + 1;
      const data = devices[index]
      console.log(data)
      console.log(index, data.lMark)
      mfDemo.locatorDevice(data)
      // this.$bus.$emit("surePoint", data);
    },
    fn_open__video(val) {
      this.isShowVideo = true
    },
    fn_open__camera(val) {},
    handleDelete(item) {
      this.$confirm('确定要忽略此条信息吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        closeOnPressEscape: false,
        type: 'warning'
      })
        .then(() => {
          if (!item.id) return
          const params = {
            ids: item.id
          }
          fn_api_get_park_alarm_remove(params).then(res => {
            if (res.code == 200) {
              this.$message.success('操作成功')
              this.$emit('delete')
            } else {
              this.$message.warning('操作异常')
            }
          })
        })
        .catch(() => {
          // this.$message.info('已取消删除');
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.visula-scroll {
  width: 317px;
  // height: 116px;
  background: $background;
  backdrop-filter: blur(30px);
  padding: 6px 0 12px 0px;
  box-sizing: border-box;
  // height: 430px;
  // background: rgba(166, 201, 255, 0.12);
  // backdrop-filter: blur(22px);
  overflow: hidden;
  .visula-scroll-title {
    width: calc(100% - 32px);
    font-family: 'H_Black';
    font-style: normal;
    // font-weight: 500;
    font-size: 18px;
    color: #ebf5f5;
    text-shadow: 0px 2px 5px #0088c7;
    margin: 0 auto 5px;
    // font-weight: 700;
    padding-bottom: 4px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(213, 214, 232, 0.3);
    position: relative;
    .title-left {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
      span {
        font-family: H_Bold;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        color: #ebf5f5;
        letter-spacing: 2px;
        text-shadow: 0px 2px 5px #0088c7;
      }
    }
    .visual-divider {
      width: 93%;
      height: 2px;
      background: rgba(213, 214, 232, 0.3);
      img {
        margin-left: -4px;
        vertical-align: top;
        margin-top: -4px;
      }
    }
  }
  .visula-scroll-title::before {
    content: '';
    width: 105px;
    height: 1px;
    background: linear-gradient(90deg, #0081ff 0%, #65fcff 100%);
    box-shadow: 0px 0px 5px #0088c7;
    position: absolute;
    left: 0;
    bottom: -1px;
  }
  .visula-scroll-list {
    color: rgb(0, 0, 0);
    .visula-scroll-item {
      display: flex !important;
      margin-top: 10px;
      padding: 10px 0 10px 0;
      display: flex;
      height: 66px;
      align-items: flex-start;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      height: 100%;
      .visula-scroll-item-left {
        flex-shrink: 0;
        img {
          width: 68px;
          height: 68px;
          background: #ccc;
          vertical-align: middle;
        }
      }
      .visula-scroll-item-right {
        margin-left: 8px;
        width: 100%;
        color: $color_rgba_w;
        .item-right-top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #ebf5f5;
          font-size: 12px;
          line-height: 100%;
          font-weight: 500;
          font-family: H_Medium;

          span:nth-child(1) {
            width: 130px;
            font-family: H_Regular;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          img {
            width: 8px;
            height: 8px;
            padding: 0 4px 4px 0;
          }
          span:nth-child(3) {
            font-size: 12px;
          }
        }
        .item-right-time {
          margin-left: -8px;
          transform: scale(0.9);
          font-size: 10px;
          color: rgba(235, 245, 245, 0.8);
          margin-top: 8px;
          font-family: H_Regular;
        }
        .item-right-address {
          margin-left: -8px;
          transform: scale(0.9);
          font-size: 10px;
          color: rgba(235, 245, 245, 0.8);
          font-family: H_Regular;
        }
        .item-right-bottom {
          display: flex;
          justify-content: space-between;
          .item-right-bottom-left {
            margin-left: -4px;
            transform: scale(0.8);
            font-size: 10px;
            font-family: H_Regular;
          }
          .item-right-bottom-right {
            display: flex;
            align-items: center;
            font-size: 10px;
            img {
              width: 16px;
              height: 16px;
              margin-left: 12px;
              vertical-align: top;
              cursor: pointer;
            }
            img:nth-child(2) {
              width: 16px;
              height: 16px;
              vertical-align: top;
            }
          }
        }
      }
    }
  }
}

.seamless-warp {
  // height: 389px;
  height: 100%;
  overflow: hidden;
}

.is-active-y {
  color: $color_y;
}
.is-active-v {
  color: $color;
}
.is-active-w {
  color: rgba(235, 245, 245, 0.5);
}

.is-level-1 {
  border-left: 2px solid $color_r;
  background: linear-gradient(
    90.01deg,
    rgba(255, 54, 54, 0.15) 0.01%,
    rgba(255, 48, 48, 0.15) 0.02%,
    rgba(255, 48, 48, 0.04) 99.99%
  );
}
.is-level-2 {
  border-left: 2px solid $color_o;
  background: linear-gradient(
    89.99deg,
    rgba(234, 123, 21, 0.15) 0.01%,
    rgba(234, 123, 21, 0.04) 99.99%
  );
}
.is-level-3 {
  border-left: 2px solid $color_y;
  background: linear-gradient(
    89.98deg,
    rgba(255, 215, 51, 0.15) 0.02%,
    rgba(255, 215, 51, 0.04) 99.98%
  );
}
.is-level-4 {
  border-left: 2px solid $color_g;
  background: linear-gradient(
    90deg,
    rgba(23, 230, 161, 0.15) 0%,
    rgba(23, 230, 161, 0.04) 100%
  );
}
.is-level-5 {
  border-left: 2px solid $color_g;
}
</style>
