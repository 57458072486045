<template>
  <visual-panel :title="title">
    <!-- 公共头左侧 -->
    <template #left>
      <template v-if="titleIcon !== ''">
        <slot name="left">
          <img :src="titleIcon" />
        </slot>
      </template>
    </template>
    <!-- 公共头右侧 -->
    <template #right>
      <slot name="custom-right"></slot>
    </template>

    <template #center>
      <div class="monitor">
        <!-- tab切换 -->
        <!-- 特例 swiper tabs -->
        <div v-if="tabType == 1" class="tabs-default flex">
          <span class="left-arrow" @click="handleSwiperPrev">
            <img
              v-if="activeIndex == 0"
              src="~@/assets/images/index/left-icon.png"
              alt=""
            />
            <img
              v-else
              src="~@/assets/images/index/left-hover-icon.png"
              alt=""
            />
          </span>

          <swiper
            ref="mySwiper"
            :options="swiperOption"
            class="tabs-default-list"
          >
            <swiper-slide
              v-for="(item, index) in tabsListData"
              :key="index"
              :class="activeIndex == index ? 'active' : ''"
            >
              <span @click="fn_handle_activeClick">{{ item.name }}</span>
            </swiper-slide>
          </swiper>

          <span class="right-arrow" @click="handleSwiperNext">
            <img
              v-if="activeIndex == tabsListData.length - 1"
              src="~@/assets/images/index/right-icon.png"
              alt=""
            />
            <img
              v-else
              src="~@/assets/images/index/right-hover-icon.png"
              alt=""
            />
          </span>
        </div>
        <!-- 特例  无人机 tabs -->
        <div v-if="tabType == 2" class="tabs">
          <ul class="flex">
            <li :class="tabsIndex == 0 ? 'active' : ''" @click="handleTabs(0)">
              无人机
            </li>
            <li :class="tabsIndex == 1 ? 'active' : ''" @click="handleTabs(1)">
              停机坪
            </li>
          </ul>
        </div>
        <!-- 自定义tabs -->
        <div v-if="tabType == 0" class="custom-tabs">
          <slot name="custom"></slot>
        </div>
        <!--  -->

        <!-- 滚动数据列表 -->
        <div class="monitor-list">
          <!-- scrollHeight -8 : 样式表中增加了 padding-top : 8px -->
          <vue-seamless-scroll
            :data="scrollList"
            class="scroll-view"
            :style="{ height: scrollHeight ? scrollHeight - 8 + 'px' : '' }"
            :class-option="defaultOption"
          >
            <div
              v-for="(item, index) in scrollData"
              :key="index"
              class="monitor-item"
            >
              <img src="~@/assets/images/security/equipment-icon.png" />
              <div class="monitor-item-info">
                <div class="monitor-item-info-head">
                  <h5>{{ item.name || item.facilityName }}</h5>
                  <span v-if="item.isOnline == 0" class="color_o">故障</span>
                  <span
                    v-else-if="item.isOnline == 1"
                    class="color_g"
                  >正常</span>
                  <span v-else class="color_grey">离线</span>
                </div>
                <div class="monitor-item-info-head">
                  <p>{{ item.setPosition }}</p>
                  <p></p>
                </div>
              </div>
            </div>
          </vue-seamless-scroll>
        </div>
        <!--  -->
      </div>
    </template>
  </visual-panel>
</template>

<script>
// swiper  局部引入
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

//
export default {
  name: 'VisualTabsChange',
  components: {
    swiper,
    swiperSlide
  },
  props: {
    title: {
      type: String,
      // require:true,
      default: '默认标题'
    },
    titleIcon: {
      type: String,
      default: ''
    },
    background: {
      //
      type: String
    },
    // tabs 类型  0 自定义  1 swiper  2 无人机固定 2项
    tabType: {
      type: Number,
      default: 0
      // require:true
    },
    // tabs 切换数据源     or  swiper 切换数据源
    tabsListData: {
      type: Array,
      // require:true,
      default: () => [
        {
          name: '水浸传感器'
        },
        {
          name: '烟雾报警器'
        },
        {
          name: '声光报警器'
        },
        {
          name: '热水表'
        },
        {
          name: '三相电表'
        },
        {
          name: '风速传感器'
        }
      ]
    },
    // 滚动视图高度
    scrollHeight: {
      type: Number,
      default: 196
    },
    // 滚动数据
    scrollList: {
      type: Array,
      // require:true,
      default: () => [
        {
          image: '', // 封面图
          title: '', // 标题
          status: 0, // //状态  0 故障  1正常   //待定
          address: '' // 地址
        },
        0,
        0,
        0,
        0
      ]
    }
  },
  data() {
    return {
      activeIndex: 0, // //swiper 当前项下标
      tabsIndex: 0, // tabs  当前项的下标    与swiper下标 独立区分  (swiper 为传入的数据     tabsIndex 为停机坪 固定数据下标)
      // swiper 配置
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 16,
        // centeredSlides: true,
        mousewheelControl: true,
        observeParents: true,
        on: {
          // slideChangeTransitionEnd: this.swiperChange,
          click: this.swiperSlideTo
        }
      },
      scrollData: []
      // swiper 配置 end
    }
  },
  computed: {
    swiper() {
      // 初始化  后续使用为this.swiper 而不是 this.$refs.mySwiper
      return this.$refs.mySwiper.swiper
    },
    defaultOption() {
      return {
        step: 0.5, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    }
  },
  watch: {
    tabsListData() {
      this.scrollData =
        this.tabsListData.length > 0 && this.tabsListData[0].list
    },
    scrollList() {
      // scrollList  存在就使用
      this.scrollData = this.scrollList
    },
    activeIndex(nextVal, preVal) {}
  },
  methods: {
    fn_handle_activeClick() {
      this.$emit('activeChange', this.activeIndex)
    },
    handleChangeData(index) {
      this.scrollData = this.tabsListData[index].list
    },
    handleSwiperPrev() {
      this.swiper.slidePrev(500, true)
      if (this.activeIndex <= 0) return
      this.activeIndex--
      // 切换滚动数据
      this.handleChangeData(this.activeIndex)
    },
    handleSwiperNext() {
      this.swiper.slideNext(500, true)
      if (this.activeIndex >= this.tabsListData.length - 1) return
      this.activeIndex++
      // 切换滚动数据
      this.handleChangeData(this.activeIndex)
    },
    swiperSlideTo() {
      // swiper项之间设置了边距  可能会点击到这个边距 导致this.swiper.clickedIndex 为undefined 需过滤此类情况
      if (typeof this.swiper.clickedIndex !== 'undefined') {
        this.activeIndex = this.swiper.clickedIndex
        // 切换滚动数据
        this.handleChangeData(this.activeIndex)
        this.$emit('swiperChange', this.activeIndex) // 将 activeIndex 手动关联swiper  this.activeIndex  为当前下标

        this.swiper.slideTo(this.swiper.clickedIndex, 500, false)
      }
    },
    // 废弃   swiper实例传出的下标 是错的    (使用了slidesPerView  导致swiper 下标错误   swiper内部错误)
    // swiperChange() {
    //   //回调当前项的下标
    //   // this.$emit("swiperChange", this.swiper.activeIndex);
    // },
    handleTabs(index) {
      this.tabsIndex = index
      // 回调当前下标
      this.$emit('swiperChange', this.tabsIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.monitor {
  // background: rgba(166, 201, 255, 0.12);
  // backdrop-filter: blur(22px);
  padding: 0 0 0 10px;
  .tabs-default {
    height: 48px;
    align-items: center;
    .left-arrow {
      height: 24px;
      padding-right: 8px;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .right-arrow {
      height: 24px;
      padding-left: 8px;
      cursor: pointer;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .tabs-default-list {
      // width: 288px;
      width: 100%;
      height: 48px;
      .swiper-slide {
        width: auto !important;
        flex-shrink: 0;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      span {
        font-size: 14px;
        line-height: 16px;

        color: rgba(255, 255, 255, 0.42);
        // margin-right: 16px;
        cursor: pointer;
      }
      .active {
        color: #ffffff;
        span {
          color: #ffffff;
        }
      }
      // .active::before {
      //   content: "";
      //   width: 28px;
      //   height: 4px;
      //   position: absolute;
      //   bottom: 8px;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   background: #8280ff;
      //   // border-radius: 5px;
      // }
    }
  }
  .tabs {
    height: 48px;
    ul {
      align-content: center;
      li {
        width: 50%;
        padding: 12px 0 18px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        color: #ffffff;
        cursor: pointer;
        position: relative;
      }
      .active::before {
        content: "";
        width: 28px;
        height: 4px;
        position: absolute;
        bottom: 8px;
        left: 50%;
        transform: translateX(-50%);
        background: $color;
        border-radius: 5px;
      }
    }
  }
  .monitor-list {
    padding-bottom: 12px;
    .scroll-view {
      height: 196px;
      overflow: hidden;
      .monitor-item {
        display: flex;
        align-items: flex-start;
        padding: 8px 0 8px;
        img {
          flex-shrink: 0;
          width: 24px;
          height: 24px;
        }
        .monitor-item-info {
          flex: 1;
          padding-left: 8px;
          position: relative;
          .monitor-item-info-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 4px 0;

            h5 {
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 100%;
              color: #ffffff;
              max-width: 70%;
            }
            span {
              font-style: normal;
              font-weight: bold;
              font-size: 12px;
              line-height: 100%;
              color: #ea7b15;
            }
          }
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 10px;
            line-height: 100%;
            color: rgba(255, 255, 255, 0.72);
            text-align: right;
          }
        }
        .monitor-item-info::before {
          content: "";
          width: 100%;
          height: 1px;
          transform: scaleY(0.5);
          background: rgba(255, 255, 255, 0.2);
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}
.color_o {
  color: $color_o !important;
}
.color_g {
  color: $color_g !important;
}
.color_grey {
  color: $color_grey !important;
}
</style>
