<!--
 * @Description:
 * @Version: 1.0
 * @Autor: hs
 * @Date: 2021-07-30 18:05:27
 * @LastEditors: hs
 * @LastEditTime: 2021-10-14 16:43:41
-->
<template>
  <div class="date-switch">
    <p @click="handleChange(0)">{{ leftText }}</p>
    <p @click="handleChange(1)">{{ rightText }}</p>
    <span :style="{left:mode ? '50%':'0'}">{{ mode ? rightText:leftText }}</span>
  </div>
</template>
<script>
export default {
  name: 'DateSwitch',
  props: {
    leftText: {
      type: String,
      default: '今日'
    },
    rightText: {
      type: String,
      default: '本月'
    }
  },
  data() {
    return {
      mode: 0 // 0 今日  1 本月
    }
  },
  watch: {
    mode() {}
  },
  mounted() {},
  methods: {
    handleChange(index) {
      this.mode = index
      this.$emit('change', index)
    }
  }
}
</script>
<style lang="scss" scoped>
.date-switch {
  width: 72px;
  height: 18px;
  background: #333333;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  p {
    width: 50%;
    text-align: center;
    line-height: 18px;
    color: rgba(235, 245, 255, 0.45);
    font-size: 10px;
    transform: scale(0.8);
    cursor: pointer;
    font-family: H_Regular;
    text-shadow: none;
  }
  span {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 18px;
    background: linear-gradient(103.71deg, #0081ff 0.54%, #65fcff 99.72%);
    font-family: H_Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    color: #EBF5F5;
    transition: all 0.3s;
    cursor: pointer;

  }
}
</style>
